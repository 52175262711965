import React from "react"
import { v4 as uuidv4 } from "uuid"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  slidesToScroll: 1,
  draggable: false,
  arrows: false,
  focusOnSelect: false,
}

export default function QuotePanel({ person, text, cls }) {
  return (
    <Slider {...settings}>
      {testimonials.map((t, i) => (
        <div className={`quote-panel ${cls}`} key={uuidv4()}>
          <div className="testimonials_head">
            <div className="left_line"></div>
            <div className="center_image">
              <img src="/images/quote_open.png" />
            </div>
            <div className="right_line"></div>
          </div>
          <div className="testimonials_txt">
            <p>{t.text}</p>
            <p>
              from {t.name} - {t.position}
            </p>
          </div>
          <div className="testimonials_foot">
            <div className="left_line"></div>
            <div className="center_image">
              <img src="/images/quote_close.png" />
            </div>
            <div className="right_line"></div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

QuotePanel.defaultProps = {
  cls: "purple",
  person: "",
  text: "A nice review....",
}

const testimonials = [
  {
    name: "Austin Jackson",
    position: "Vistage Chair",
    text: `If you want to get your Long Term Care Planning right for you and your spouse, you must speak
to Corey. His Phase I made sure I understood a fairly complex set of options – no pressure. Phase
II only proceeds if you want to. It was only because of his knowledge of the individual preferences
of the carriers that he was able to provide the best plan for us. To cap it, he guided us through the
many funding options until we had exactly what we needed. I am so glad he is on my side.`,
  },
  {
    name: "Benjamin R. Loggins, CPA",
    position: "Loggins and Associates",
    text: `I have used Corey’s Long Term Care expertise over the years for many of my clients. I am
comfortable in doing so because of his experience and his knowledge of all of the LTC carrier
options as they relate to plan design, underwriting and funding. In fact, I believe so strongly in his
experience my wife and I are Long Term Care clients of his.`,
  },
  {
    name: "Lance LoRusso",
    position: "LoRusso Law Firm",
    text: `When I implemented Long Term Care Planning in my law firm, Corey used his educational systems
to help my employees and their spouses understand the relevant issues of Long Term Care. After
a group presentation, Corey met with each employee in a private setting to obtain information
regarding their individual needs for Long Term Care Insurance, their medical history, and their
financial circumstances.The purchase of Long Term Care Insurance is a deeply personal decision
that requires a process to ensure a successful outcome. Corey’s methods bring that process to
fruition. More than any other type of insurance, Long Term Care Insurance is not a “one size fits
all” prospect. Corey has the knowledge, experience, and determination to explore and obtain the
best solutions for each individual client.`,
  },
  {
    name: "William Hoversten, ESQ.",
    position: "Patton, Hoversten and Berg",
    text: `Corey is very knowledgeable and someone who can conduct a very in-depth, informative Long Term Care conversation with clarity.  He has always exhibited excellent follow-through and is exceptional at explaining Long Term Care information clearly and concisely.  The feedback on Corey from my clients is always very positive.`,
  },
  {
    name: "Jennifer White",
    position:
      "Director of Corporate Relations, Corporation for Long Term Care Certification, Inc.",
    text: `As a long term care training company, Corey is a highly valued instructor for CLTC (www.ltc-cltc.com) and his training and educational abilities are not only helpful for the advisors he teaches but also translatable in their explanation to his clients. He is the most process driven, committed and detail oriented advisor I know. Since 2003, he has trained 3,500 advisors from San Francisco to Wall Street on how to properly educate their clients on Long Term Care through our company. Our organization has always received positive feedback on Corey and the students have always commented positively on his level of professionalism and overall mastery of the LTC industry.`,
  },
  {
    name: "J.D.",
    position: "Tucker, Georgia",
    text: `I had been declined twice earlier for Long Term Care, and then I met Corey.  He took the time to understand my medical history fully; and because of his system,  extensive experience and knowledge of underwriting parameters/standards from the LTC carriers he helped me secure coverage from one of the industry leaders.`,
  },
  {
    name: "Dr. Barbara G.",
    position: "Atlanta, Georgia",
    text: `Corey understands Long Term Care education, underwriting, funding and plan implementation better than anyone, and his service is excellent. He is different in that he has excellent knowledge of the products and he understands the many pitfalls that the underwriting process itself can present. In addition to being a strong advocate for his clients Corey is also a gifted Long Term Care trainer and has trained thousands of advisors and industry professionals across the country since 2003. I refer Corey’s services because I know the person will get the solution that is best for them and that they will receive excellent education.`,
  },
]
